import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import tw from "tailwind.macro"
import { useStaticQuery, graphql } from "gatsby"
import {
  H4,
  GrayText,
  GrayTextWrapper,
  BodyText,
  TagText
} from "../elements/shared/texts"


export const LoginButton = styled(motion.button)`
  ${tw`block  py-2 px-5 text-white font-medium text-sm sm:text-sm lg:text-md`};
  background-image: linear-gradient(
    -90deg,
    rgb(21, 195, 239),
    rgb(16, 124, 231)
  );
  border-radius: 10px;
`

const Card = styled.div`
  ${tw`flex`};
  cursor: pointer;
  transform: scale(1);
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  animation: color 0.5s;
  border-width: 1px;
  border-color: ${props => props.theme.colors.border};
  border-radius: 20px;

  &:hover{  
    h4{
      color: ${props => props.theme.colors.accent};
    }
    
  }
  


  .wrapper{
    position: relative;
  }

  .image-container {
    position: relative;
    transition: 0.5s;
    border-radius: 20px;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 30px 60px ${props => props.theme.colors.mainShadow};
  }

  .details-container {
    background-color: ${props => props.theme.colors.deepBackground};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    transform: translateY(150px);
    /* opacity: 0; */
    /* border-radius: 0 0 20px 20px; */

    transition: 0.5s;
  }

  img{
    transition: transform .5s ease;
  }

  &:hover .image-container {
    transform: translateY(0);
    img{
      transform: scale(1.1);
    }
  }

  &:hover .details-container {
    transform: translateY(0);
    opacity: 1;
 }
  
`

const BlogCard = ({ article }) => {
  const data = useStaticQuery(
    graphql`
      query {
        pattern: file(relativePath: { eq: "courses_pattern.svg" }) {
          publicURL
        }
        placeholder: file(relativePath: { eq: "lastpass-clone.jpg" }) {
          publicURL
        }
        previewCourse: file(relativePath: { eq: "preview_course.png" }) {
          publicURL
        }
      }
    `
  )

  return (
    <Card>
      <div className="wrapper">
        <div className="image-container">
          <img src={article.node.image}  alt={article.node.title}/>
          <div className="px-4 py-4 details-container">
          <GrayTextWrapper>
            <small>{article.node.createdDate}</small>
          </GrayTextWrapper>
          <H4>{article.node.title}</H4>
          <div className="mt-3">
            {article.node.tags.map(tag => (
              <TagText key={tag.title}>{tag.title}</TagText>
            ))}
          </div>
        </div>
        </div>
 
      </div>
    </Card>
  )
}

export default BlogCard
