import  React  from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import tw from "tailwind.macro"
import Article from "../components/Article"
import Pagination from "../components/Pagination"
import { Link } from "gatsby";
import ListHeader from "../components/ListHeader"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Container = styled.div`
  ${tw`pb-8`};
  background-color: ${props => props.theme.colors.background};
`

const Blog = ({ data, pageContext }) => {



  return (
    <Layout>
      <SEO url={"/blog"}
          title={"Liquidcoder blog"}
           description={"Weekly articles about iOS, macOS, watchOS, iPadOS, swiftUI, app development and more (Golang, Android, Flutter)"}
      />
      <ListHeader title={"Blog"} />
      <Container>
        <div className="container mx-auto grid  px-4 lg:grid-cols-2 gap-6 sm:gap-12 grid-cols-1">
          {data.allContentfulArticle.edges.map(article => (
            <Link key={article.node.slug} to={`/article/${article.node.slug}`}>
              <Article article={article} />
            </Link>
          ))}
        </div>
        <Pagination  page={"blog"} totalPages={pageContext.numPages} currentPage={pageContext.currentPage} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query blogQuery($skip: Int!, $limit: Int!) {
    allContentfulArticle(
      sort: { fields: createdDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          createdDate(formatString: "dddd, DD MMMM YYYY")
          id
          image
          slug
          title
          tags {
            title
          }
          updatedDate(fromNow: false)
          videoUrl
          body {
            childMdx {
              excerpt(pruneLength: 300)
            }
          }
        }
      }
    }
  }
`

export default Blog
